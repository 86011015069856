import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiUsers , FiChevronUp , FiFileText , FiMonitor , FiShoppingCart , FiDollarSign , FiHome } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import WhatsAppButton from "../component/WhatsAppButton";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

const ServiceList = [
    {
        icon: <FiFileText />,
        title: 'Consultoría contable y jurídica',
        description: 'Implantación del negocio en el país definiendo las mejores estrategias con las administraciones publicas.',
        slug: "service-1"
    },
    {
        icon: <FiShoppingCart />,
        title: 'E-commerce consulting',
        description: 'Estudios de la industria y la mejor gestión logistica para satisfacer la demanda en plataformas digitales.',
        slug: "service-2"
    },
    {
        icon: <FiDollarSign />,
        title: 'Estrategias financieras',
        description: 'Implementando análisis, proyección y gestión corporativa con la banca, adquisiciones inmobiliarias.',
        slug: "service-3"
    },
    { 
        icon: <FiHome />,
        title: 'Real estate',
        description: 'Asesoría, gestión de propiedades, análisis de mercado y estrategias para maximizar el valor de activos inmobiliarios.',
        slug: "service-4"
    },
    {
        icon: <FiMonitor />,
        title: 'Diseño, IA y social media',
        description: 'Desarrollo o rediseño web, diseño visual, imagen corporativa e implementación de IA en diferentes procesos.',
        slug: "service-5"
    },
    { 
        icon: <FiUsers />,
        title: 'Gestion de talento',
        description: 'Podemos identificar, seleccionar y gestionar  el talento y los recursos humanos más adecuados para cada proyecto.',
        slug: "service-6"
    },
]
class Service extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Servicios' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Breadcrump Area */}
            <div
            className="rn-page-title-area pt--120 pb--190 bg_image services-9"
            data-black-overlay="6"
            >
            <div className="container">
                <div className="row">
                <div className="col-lg-12">
                    <div className="rn-page-title text-center pt--100">
                    <h2 className="title theme-gradient">Servicios</h2>
                    <p>
                        Ofrecemos una amplia gama de prestaciones diseñadas para potenciar el crecimiento,
                        <br />
                        mejorar la eficiencia y asegurar el éxito de tu organización
                    </p>
                    </div>
                </div>
                </div>
            </div>
            </div>
            {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row creative-service">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href={`/details-${val.slug}`}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                {/* Start Whatsapp Button */}
                    <div>
                        <WhatsAppButton> </WhatsAppButton>
                    </div>
                {/* End Whatsapp Button */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default Service;