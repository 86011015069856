import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import TeamTwo from "../blocks/team/TeamTwo";
import BrandTree from "../elements/BrandTree";
import BrandFour from "../elements/BrandFour";
import BrandFive from "../elements/BrandFive";
import ScrollToTop from "react-scroll-up";
import WhatsAppButton from "../component/WhatsAppButton";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import about from "../assets/images/about/about-3.jpg";

class About extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Nosotros" />

        <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Breadcrump Area */}
            <div
            className="rn-page-title-area pt--120 pb--190 bg_image services-8"
            data-black-overlay="6"
            >
            <div className="container">
                <div className="row">
                <div className="col-lg-12">
                    <div className="rn-page-title text-center pt--100">
                    <h2 className="title theme-gradient">Nosotros</h2>
                    <p>
                      Nos especializamos en identificar y desarrollar proyectos con el objetivo de expandirlos en los Estados Unidos
                      <br />
                      Trabajamos codo a codo con nuestros clientes para convertir sus ideas en realidades exitosas
                    </p>
                    </div>
                </div>
                </div>
            </div>
            </div>
            {/* End Breadcrump Area */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--70 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src={about}
                      alt="About Images"
                    />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="row mt--30">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">¿Cuando empezamos?</h3>
                          <p>
                          Nuestra actividad comienzo en 1999, hace  25 años
                          en Madrid España vinculada al sector textil inicialmente
                          colaborando con GP GRUPO INMOBILIARIO E INDITEX (ZARA)
                          entre otras marcas del sector, esto nos llevó a implantar
                          una división  especializada  en el sector inmobiliario para
                          cubrir  la necesidad de expandir el negocio textil.
                          </p>
                          <p>
                          Tras varios años se creó la división en patrimonio
                          inmobiliario y otra de alimentos y bebidas dando servicio
                          a empresas del sector que implantaban marcas y productos
                          nuevos en el mercado.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Llegó el momento de expandirse</h3>
                          <p>
                          Es en el 2018 cuando estudiando las condiciones tan favorables
                          de implantar negocios EEUU, Florida, Miami principalmente,
                          decidimos trasladar la actividad a la ciudad.
                          </p>
                          <p>
                          Esto nos ha ayudado a identificar las necesidades de cada mercado
                          y las oportunidades de negocio en USA de cada sector.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start Team Area */}
        <div className="rn-team-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                  <h2 className="title3">Nuestro equipo</h2>
                  {/* <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration.
                  </p> */}
                </div>
              </div>
            </div>
            <div className="row">
              <TeamTwo column="col-lg-2" teamStyle="" item="6" />
            </div>
          </div>
        </div>
        {/* End Team Area */}

        {/* Start Brand Area */}
        <div className="rn-brand-area bg_color--1 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTree />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}

        {/* Start Brand Area */}
        <div className="rn-brand-area bg_color--5 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandFour />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}

        {/* Start Brand Area */}
        <div className="rn-brand-area bg_color--1 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandFive />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}

        {/* Start Whatsapp Button */}
        <div>
          <WhatsAppButton> </WhatsAppButton>
        </div>
        {/* End Whatsapp Button */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default About;
