import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import WhatsAppButton from "../component/WhatsAppButton";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import serviceImg from "../assets/images/service/service-10.jpg";

class Club_senior extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Club Senior" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image services-11"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Club Senior</h2>
                  <p>Global Business Community</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="about-inner inner">
                  <div className="section-title">
                    <p className="description">
                      Con este club senior buscamos la excelencia de la experiencia
                      esta solo la consiguen las personas que se han formado durante
                      muchos años, durante muchos más han experimentado en la vida
                      y en sus trabajos y a día de hoy tienen la sabiduría destreza
                      seguridad y serenidad que es un auténtico tesoro para las personas
                      que están emprendiendo y necesitas una visión experta y global.
                    </p>
                    <p className="description">
                      Por eso buscamos a esas personas que están en su mejor momento,
                      en los 70, 80, 90 años. Queremos emprender con ellos, su gran
                      aporte le son útiles a las nuevas generaciones.
                    </p>
                    <p className="description">
                      El fin de este club es seleccionar a 8 senior de cada país
                      experimentados en las diferentes disciplinas económicas
                      y empresariales de USA, COLOMBIA, MÉXICO, ESPAÑA Y CHINA para
                      formar un consejo senior que asesore cada uno de los emprendimientos
                      que decidamos apoyar.
                    </p>
                    <div className="rn-form-group mt--30">
                      <a
                        className="rn-button-style--2 btn-solid"
                        href="https://wa.me/17866047755?text=Hola, quiero participar en el Club Senior"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ¡Quiero participar!
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <div className="thumbnail position-relative">
                  <img
                    className="w-100"
                    src={serviceImg}
                    alt="Service Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        {/* Start Whatsapp Button */}
          <div>
            <WhatsAppButton> </WhatsAppButton>
          </div>
        {/* End Whatsapp Button */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default Club_senior;
