import React ,{ Component }from "react";
import {FiFileText , FiUsers , FiMonitor , FiShoppingCart , FiDollarSign , FiHome} from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiFileText />,
        title: 'Consultoría contable y jurídica',
        description: 'Implantación del negocio en el país definiendo las mejores estrategias con las administraciones publicas.',
        slug: "service-1"
    },
    {
        icon: <FiShoppingCart />,
        title: 'E-commerce consulting',
        description: 'Estudios de la industria y la mejor gestión logistica para satisfacer la demanda en plataformas digitales.',
        slug: "service-2"
    },
    {
        icon: <FiDollarSign />,
        title: 'Estrategias financieras',
        description: 'implementando análisis, proyección y gestión corporativa con la banca y adquisiciones inmobiliarias de cada actividad.',
        slug: "service-3"
    },
    { 
        icon: <FiHome />,
        title: 'Real estate',
        description: 'Gestión de propiedades, análisis de mercado y estrategias para maximizar el valor de activos inmobiliarios.',
        slug: "service-4"
    },
    {
        icon: <FiMonitor />,
        title: 'Diseño, IA y social media',
        description: 'Desarrollo o rediseño web, diseño visual, imagen corporativa e implementación de IA en diferentes procesos.',
        slug: "service-5"
    },
    { 
        icon: <FiUsers />,
        title: 'Gestion de talento',
        description: 'Podemos identificar, seleccionar y gestionar  el talento y los recursos humanos más adecuados para cada proyecto.',
        slug: "service-6"
    }
]

class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href={`/details-${val.slug}`}>
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
