import React from 'react';
import GoogleMapReact from 'google-map-react';

const MyGoogleMap = () => {
    // Centro predeterminado del mapa y nivel de zoom
    const defaultProps = {
        center: {
            lat:25.770871004763332,
            lng:-80.18999143934927
        },
        zoom: 14
    };

    const handleApiLoaded = (map, maps) => {
        // Crear el marcador predeterminado de Google Maps
        new maps.Marker({
            position: defaultProps.center,
            map,
        });
    };

    return (
        <div style={{ height: '550px', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyBZ1DKHfmy-q-g3wPhUvXq8AFEz4_wOXMI' }} // Reemplaza con tu clave API
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                />
        </div>
    );
};

export default MyGoogleMap;
