import React, { Component } from "react";
import {Link} from "react-router-dom";

const PortfolioListContent = [
    {
        image: 'image-1',
        category: 'Eku Smart Home | Miami',
        title: 'SMART LOCKS THE FUTURE'
    },
    {
        image: 'image-2',
        category: 'Baijax | USA',
        title: 'REVOLUTIONARY PANELS EXCLUSIVE SPACES'
    },
    {
        image: 'image-3',
        category: 'Norma | USA',
        title: 'CALIDAD DISEÑO Y VOLUMEN'
    },
    {
        image: 'image-4',
        category: 'Easy Home | USA',
        title: 'FONDO INMOBILIARIO ENFOCADO EN VIVIENDAS MODULARES'
    },
    {
        image: 'image-5',
        category: 'Magnum | Miami',
        title: 'SPANISH WINE HOUSE'
    },
    {
        image: 'image-6',
        category: 'TU EMPRESA | EL MUNDO',
        title: '¡SAQUEMOS ESE PROYECTO ADELANTE!'
    }
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <h4><a href="/portfolio-details">{value.title}</a></h4>
                                    {/* <div className="portfolio-button">
                                        <a className="rn-btn" href="/portfolio-details">View Details</a>
                                    </div> */}
                                </div>
                            </div>
                            {/* <Link className="link-overlay" to="/portfolio-details"></Link> */}
                        </div>
                    </div>
                ))}            
            </React.Fragment>
        )
    }
}
export default PortfolioList;
