import React from "react";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";

import teamImg1 from "../../assets/images/team/Ethan-Ye.png";
import teamImg2 from "../../assets/images/team/Maria-Sierra.png";
import teamImg3 from "../../assets/images/team/Marcela-Salazar.png";
import teamImg4 from "../../assets/images/team/Sebastian-Valencia.png";
import teamImg5 from "../../assets/images/team/Margarita-Chona.png";
import teamImg6 from "../../assets/images/team/Borja-Marcos.png";
import teamImg7 from "../../assets/images/team/team-11.jpg";
import teamImg8 from "../../assets/images/team/team-09.jpg";

let data = [
  {
    images: teamImg1,
    title: "Ethan Ye",
    designation: "Evaluación de negocios internacionales en Europa y Asia",
    socialNetwork: [
      // {
      //   icon: <FaFacebookF />,
      //   url: "#",
      // },
      // {
      //   icon: <FaLinkedinIn />,
      //   url: "#",
      // },
      // {
      //   icon: <FaTwitter />,
      //   url: "#",
      // },
    ],
  },
  {
    images: teamImg2,
    title: "Maria Sierra",
    designation: "Marketing digital y estrategias de mercado nacional",
    socialNetwork: [
      // {
      //   icon: <FaFacebookF />,
      //   url: "#",
      // },
      // {
      //   icon: <FaLinkedinIn />,
      //   url: "#",
      // },
    ],
  },
  {
    images: teamImg3,
    title: "Marcela Salazar",
    designation: "Asesora fiscal experta en constitución de corporaciones",
    socialNetwork: [
      // {
      //   icon: <FaFacebookF />,
      //   url: "#",
      // },
      // {
      //   icon: <FaLinkedinIn />,
      //   url: "#",
      // },
      // {
      //   icon: <FaTwitter />,
      //   url: "#",
      // },
    ],
  },
  {
    images: teamImg4,
    title: "Sebastian Valencia",
    designation: "Desarrollo web, procesos digitales e implantación de IA",
    socialNetwork: [
      // {
      //   icon: <FaFacebookF />,
      //   url: "#",
      // },
      // {
      //   icon: <FaLinkedinIn />,
      //   url: "#",
      // },
      // {
      //   icon: <FaTwitter />,
      //   url: "#",
      // },
    ],
  },
  {
    images: teamImg5,
    title: "Margarita Chona",
    designation: "Analista financiero",
    socialNetwork: [
      // {
      //   icon: <FaFacebookF />,
      //   url: "#",
      // },
      // {
      //   icon: <FaLinkedinIn />,
      //   url: "#",
      // },
    ],
  },
  {
    images: teamImg6,
    title: "Borja Marcos",
    designation: "Gestor de proyectos y desarrollos corporativos",
    socialNetwork: [
      // {
      //   icon: <FaFacebookF />,
      //   url: "#",
      // },
      // {
      //   icon: <FaLinkedinIn />,
      //   url: "#",
      // },
      // {
      //   icon: <FaTwitter />,
      //   url: "#",
      // },
    ],
  },
  {
    images: teamImg4,
    title: "Fatima Amar",
    designation: "Sr. Graphic Designer",
    socialNetwork: [
      {
        icon: <FaFacebookF />,
        url: "#",
      },
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
    ],
  },
  {
    images: teamImg7,
    title: "John Dou",
    designation: "Sr. Graphic Designer",
    socialNetwork: [
      {
        icon: <FaFacebookF />,
        url: "#",
      },
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
    ],
  },
  {
    images: teamImg8,
    title: "John Dou",
    designation: "Sr. Graphic Designer",
    socialNetwork: [
      {
        icon: <FaFacebookF />,
        url: "#",
      },
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
    ],
  },
  {
    images: teamImg7,
    title: "John Dou",
    designation: "Sr. Graphic Designer",
    socialNetwork: [
      {
        icon: <FaFacebookF />,
        url: "#",
      },
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
    ],
  },
];

export default data;
