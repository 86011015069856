import React from 'react';
import logo_wp from "../assets/images/icons/WhatsApp.png";

const WhatsAppButton = () => {
    const phoneNumber = "17866047755"; // Reemplaza con tu número de teléfono
    const message = "Hola, estoy interesado en tus servicios."; // Mensaje que se enviará

    const handleClick = () => {
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
    };

    return (
        <button onClick={handleClick} style={buttonStyle}>
            <img
            src={logo_wp} // URL del logo de WhatsApp
            alt="logo_WhatsApp"
            style={iconStyle}
            />
        </button>
    );
};

const buttonStyle = {
    position: 'fixed',  // Hace que el botón permanezca fijo durante el scroll
    bottom: '50px',     // Espaciado desde la parte inferior
    right: '50px',      // Espaciado desde la parte derecha
    backgroundColor: '#25D366',
    border: 'none',
    padding: '15px',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',  // Sombra para darle algo de profundidad
    zIndex: 99,
};

const iconStyle = {
    width: '40px',
    height: '40px',
};

export default WhatsAppButton;
