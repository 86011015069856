import React, { Component } from "react";

class Breadcrumb extends Component{
    render(){
        const { title } = this.props;
        return(
            <React.Fragment>
                <div className="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--1"> {/*data-black-overlay="6"*/}
                    <div className="breadcrumb-inner">
                        <h2 className="title">{title}</h2>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Breadcrumb;

