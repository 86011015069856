import React, { Component } from "react";

import brand1 from "../assets/images/brand/porcelanosa.png";
import brand2 from "../assets/images/brand/sfera.png";
import brand3 from "../assets/images/brand/amlocales.png";
import brand4 from "../assets/images/brand/lgan.png";
import brand5 from "../assets/images/brand/caliplac.png";
import brand6 from "../assets/images/brand/zara.png";
import brand7 from "../assets/images/brand/legg.png";
import brand8 from "../assets/images/brand/reny-picto.png";
import brand9 from "../assets/images/brand/cosentino.png";
import brand10 from "../assets/images/brand/norma.png";
import brand11 from "../assets/images/brand/uniarte.png";
import brand12 from "../assets/images/brand/inditex.png";
import brand13 from "../assets/images/brand/habitat.png";
import brand14 from "../assets/images/brand/navemar.png";
import brand15 from "../assets/images/brand/huaying.png";
import brand16 from "../assets/images/brand/albae.png";
import brand17 from "../assets/images/brand/cerveza_ocho.png";
import brand18 from "../assets/images/brand/florida.png";
import brand19 from "../assets/images/brand/easy_home.png";
import brand20 from "../assets/images/brand/baijax.png";
import brand21 from "../assets/images/brand/eku.png";
import brand22 from "../assets/images/brand/garcia.png";
import brand23 from "../assets/images/brand/olivarera.png";
import brand24 from "../assets/images/brand/domus.png";
import brand25 from "../assets/images/brand/artsana.png";
import brand26 from "../assets/images/brand/chicco.png";
import brand27 from "../assets/images/brand/reig.png";

class BrandTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="col-lg-12">
          <div className="section-title service-style--3 text-center mb--30 mb_sm--0">
            <h2 className="title2">Clientes</h2>
          </div>
        </div>
        <ul className="brand-style-2">
          <li>
            <img src={brand1} alt="Logo Images" />
          </li>
          <li>
            <img src={brand2} alt="Logo Images" />
          </li>
          <li>
            <img src={brand3} alt="Logo Images" />
          </li>
          <li>
            <img src={brand4} alt="Logo Images" />
          </li>
          <li>
            <img src={brand5} alt="Logo Images" />
          </li>
          <li>
            <img src={brand6} alt="Logo Images" />
          </li>
          <li>
            <img src={brand7} alt="Logo Images" />
          </li>
          <li>
            <img src={brand25} alt="Logo Images" />
          </li>
          <li>
            <img src={brand9} alt="Logo Images" />
          </li>
          <li>
            <img src={brand10} alt="Logo Images" />
          </li>
          <li>
            <img src={brand11} alt="Logo Images" />
          </li>
          <li>
            <img src={brand12} alt="Logo Images" />
          </li>
          <li>
            <img src={brand13} alt="Logo Images" />
          </li>
          <li>
            <img src={brand14} alt="Logo Images" />
          </li>
          <li>
            <img src={brand15} alt="Logo Images" />
          </li>
          <li>
            <img src={brand16} alt="Logo Images" />
          </li>
          <li>
            <img src={brand17} alt="Logo Images" />
          </li>
          <li>
            <img src={brand26} alt="Logo Images" />
          </li>
          <li>
            <img src={brand19} alt="Logo Images" />
          </li>
          <li>
            <img src={brand20} alt="Logo Images" />
          </li>
          <li>
            <img src={brand21} alt="Logo Images" />
          </li>
          <li>
            <img src={brand22} alt="Logo Images" />
          </li>
          <li>
            <img src={brand23} alt="Logo Images" />
          </li>
          <li>
            <img src={brand24} alt="Logo Images" />
          </li>
          <li>
            <img src={brand27} alt="Logo Images" />
          </li>
        </ul>
      </React.Fragment>
    );
  }
}
export default BrandTwo;
