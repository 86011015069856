import React, { useState } from 'react';

function ContactForm() {
    const [result, showResult] = useState(false);

    const sendEmail = async (e) => {
        e.preventDefault();
        
        // Crear un objeto FormData a partir del formulario
        const formData = new FormData(e.target);

        // Enviar la petición POST al archivo PHP
        const response = await fetch('send_email.php', {
            method: 'POST',
            body: new URLSearchParams(formData), // Enviar como x-www-form-urlencoded
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });

        if (response.ok) {
            console.log("Correo enviado exitosamente");
            showResult(true);
        } else {
            console.log("Error al enviar el correo");
        }

        setTimeout(() => {
            showResult(false);
        }, 5000);
    };

    return (
        <form onSubmit={sendEmail}>
            <div className="rn-form-group">
                <input type="text" name="fullname" placeholder="Nombre" required />
            </div>
            <div className="rn-form-group">
                <input type="email" name="email" placeholder="Email" required />
            </div>
            <div className="rn-form-group">
                <input type="text" name="phone" placeholder="Teléfono" required />
            </div>
            <div className="rn-form-group">
                <input type="text" name="subject" placeholder="Asunto" required />
            </div>
            <div className="rn-form-group">
                <textarea name="message" placeholder="Mensaje" required></textarea>
            </div>
            <div className="rn-form-group">
                <button className="rn-button-style--2 btn-solid" type="submit">Enviar</button>
            </div>
            {result && <p className="success-message">Mensaje enviado con éxito.</p>}
        </form>
    );
}

export default ContactForm;
