import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class Accordion01 extends Component {
    render(){
        return(
            <Accordion className="accodion-style--1" preExpanded={'0'}>
                <AccordionItem >
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Búsqueda
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Buscamos empresas Españolas, Colombianas, Mexicanas y Chinas con una gran proyección en el mercado Americano.</p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Propuesta
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Una vez identificada la empresa con el producto o servicio adecuado realizamos un estudio de mercado y preparamos una propuesta global para implantarla en USA de manera consensuada, eficaz y adaptada a las necesidades del mercado local.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Desarrollo
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Teniendo claro cuál es el modelo a seguir para la implantación del proyecto empresarial en USA es cuando desarrollamos toda la estrategia antes definida y con el equipo necesario para conseguir nuestro objetivo marcado.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Consolidación
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Cuando alcanzamos los primeros resultados hacemos una valoración del éxito de la estrategia. En caso de ser positiva, perfeccionamos el modelo, lo consolidamos y nos preparamos para el crecimiento y la expansión.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Crecimiento
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Una vez tenemos la corporación consolidada con referentes claros de éxito y un modelo testado que funciona, lo replicamos en diferentes ciudades y estados con recursos propios o con la incorporación de socios estratégicos.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

            </Accordion>
        )
    }
}

export default Accordion01;





