import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import WhatsAppButton from "../component/WhatsAppButton";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import serviceImg from "../assets/images/service/service-09.png";

class ServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Gestion de talento" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image services-6"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Gestion de talento</h2>
                  <p>Podemos identificar y seleccionar los recursos humanos más adecuados para cada proyecto</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="image_1"
                            src={serviceImg}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>
                            <h4 className="title">Selección, formación, motivación y retención de talento</h4>
                            Gestionar el talento humano de manera efectiva
                            es vital para la planeación consiguiendo una correcta
                            y clara gestión del trabajo de cada proceso.
                          </p>
                          <p>
                            Utilizamos bases de datos propias para la contratación
                            del personal adecuado. Buscamos hacer rápida y fácil
                            la incorporación del personal necesario para cada fase
                            del negocio.
                          </p>
                          <p>
                            Conseguimos los mejores talentos y los incorporamos
                            a los equipos para cada reto corporativo. <br />
                            Fomentamos acciones de motivación individualizada
                            proponiendo a los miembros más brillantes tener
                            participaciones de la compañía.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        {/* Start Whatsapp Button */}
          <div>
            <WhatsAppButton> </WhatsAppButton>
          </div>
        {/* End Whatsapp Button */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default ServiceDetails;
