import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer-area">
          <div className="footer-wrapper">
            <div className="row align-items-end row--0">
              <div className="col-lg-6">
                <div className="footer-left">
                  <div className="inner">
                    <span>¡BUSCAMOS LOS MEJORES PROYECTOS!</span>
                    <h2>
                      Trabajemos <br /> juntos
                    </h2>
                    <a className="rn-button-style--2" href="/contact">
                      <span>Contáctanos</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footer-right" > {/*data-black-overlay="0"*/}
                  <div className="row">
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="footer-link">
                        <h4>Acceso rápido:</h4>
                        <ul className="ft-link">
                          <li>
                            <a href="/about">Nosotros</a>
                          </li>
                          <li>
                            <a href="/service">Servicios</a>
                          </li>
                          <li>
                            <a href="/solutions">Soluciones</a>
                          </li>
                          <li>
                            <a href="/contact">Contacto</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* End Single Widget  */}
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                      <div className="footer-link">
                        <h4>Escríbenos:</h4>
                        <ul className="ft-link">
                          <li>
                            <a href="mailto:info@abybacorporate.com">
                              info@abybacorporate.com
                            </a>
                          </li>
                        </ul>
                        <br />
                        <h4>Llámanos:</h4>
                        <ul className="ft-link">
                          <li>
                            <a href="tel:+17866047755">
                              +1 (786) 604 7755 
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* End Single Widget  */}

                    <div className="col-lg-12">
                      <div className="copyright-text">
                        <p>
                          Copyright © 2024 <br />
                          Desarrollo por <a href="https://www.linkedin.com/in/valencia3dartist/" target="_blank" class="w3-hover-text-green">Sebastian Valencia</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
export default Footer;
