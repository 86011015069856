import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import WhatsAppButton from "../component/WhatsAppButton";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import serviceImg from "../assets/images/service/service-05.png";

class ServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="E-commerce consulting" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image services-2"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">E-commerce consulting</h2>
                  <p>Estudios de la industria y la mejor gestión logistica para satisfacer la demanda en plataformas digitales</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="image_1"
                            src={serviceImg}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>
                            Impulsamos las ventas de la pequeña y mediana empresa
                            mediante la implantación y la transformación del comercio
                            digital.
                          </p>
                          <p>
                            Incorporamos análisis de procesos estratégicos y estudio
                            de todas las variables que afectan al sector de cada producto
                            y tipo de consumidor.
                          </p>
                          <p>
                            Identificamos su mejor posicionamiento para maximizar
                            los resultados y obtener una gran aceptación en el mercado.
                            Usamos plataformas de información de hábitos de consumo con
                            la que seleccionamos nuestra mejor estrategia de venta.
                            Damos seguimiento al proceso de venta de principio a fin para
                            mejorarlo buscando la máxima eficiencia y satisfacción.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        {/* Start Whatsapp Button */}
          <div>
            <WhatsAppButton> </WhatsAppButton>
          </div>
        {/* End Whatsapp Button */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default ServiceDetails;
