import React, { Component, Fragment } from "react";
import ScrollToTop from "react-scroll-up";
import WhatsAppButton from "../component/WhatsAppButton";
import Slider from "react-slick";
import ServiceList from "../elements/service/ServiceList";
import BrandTwo from "../elements/BrandTwo";
import PortfolioList from "../elements/portfolio/PortfolioList";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Accordion01 from "../elements/Accordion";
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";

import about from "../assets/images/about/process.jpg";
import about2 from "../assets/images/about/about-3.jpg";
import solidarios from "../assets/images/about/old_people.jpg";

const slideSlick = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true, // Habilita el autoplay
  autoplaySpeed: 5000, // Tiempo en milisegundos entre cada slide (5 segundos en este caso)
  fade: true, // Añade un efecto de fade entre transiciones
};

const SlideList = [
  {
    textPosition: "text-left",
    bgImage: "bg_image--17",
    category: "",
    title: "Miami",
    description:
      "Atrae los proyectos de inversión más rentables",
    buttonText: "Más información",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-left",
    bgImage: "bg_image--18",
    category: "",
    title: "Colombia",
    description:
      "Buscamos los mejores negocios",
    buttonText: "Más información",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-left",
    bgImage: "bg_image--19",
    category: "",
    title: "Málaga",
    description:
      "Genera dólares, vive en euros",
    buttonText: "Más información",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-left",
    bgImage: "bg_image--20",
    category: "",
    title: "España",
    description:
      "En el mejor momento para invertir en USA",
    buttonText: "Más información",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-left",
    bgImage: "bg_image--21",
    category: "",
    title: "China",
    description:
      "Implantamos en USA las mejores compañías",
    buttonText: "Más información",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-left",
    bgImage: "bg_image--22",
    category: "",
    title: "México",
    description:
      "Fabrica en pesos vende en dólares",
    buttonText: "Más información",
    buttonLink: "/contact",
  },
];

class Business extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    var namesItemOne = [
      'Aportar creatividad',
      'Innovación',
      'Compromiso',
      'Responsabilidad',
    ];

    return (
      <Fragment>
        <Helmet pageTitle="Abyba Corporate" />
        <Header />
        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <div className="slider-activation">
            <Slider className="rn-slick-dot dot-light" {...slideSlick}>
              {SlideList.map((value, index) => (
                <div
                  className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                  key={index}
                  data-black-overlay="6"
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ""}
                          {value.title ? (
                            <h1 className="title">{value.title}</h1>
                          ) : (
                            ""
                          )}
                          {value.description ? (
                            <p className="description">{value.description}</p>
                          ) : (
                            ""
                          )}
                          {value.buttonText ? (
                            <div className="slide-btn">
                              <a
                                className="rn-button-style--2 btn-solid"
                                href={`${value.buttonLink}`}
                              >
                                {value.buttonText}
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">Nosotros</h2>
                    <p className="description">
                    Buscamos principalmente pequeñas y medianas
                    empresas o proyectos empresariales con una gran
                    proyección en el mercado americano.
                    </p>
                    <p className="description">
                    Somos un equipo joven pero con alta experiencia,
                    esto nos da la certeza a la hora de  implantar
                    compañías y marcas que aportan valor añadido
                    al mercado al que se dirigen.
                    </p>
                  </div>
                  <div className="mt--30 section-title">
                    <h4 className="subtitle-orange">Nuestra filosofía se fundamenta en 4 valores:</h4>
                    <ul className="list-style--1">
                      {namesItemOne.map((name, index) => {
                        return (
                          <li key={index}>
                            <FiCheck /> {name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <div className="thumbnail position-relative">
                  <img
                    className="w-100"
                    src={about2}
                    alt="About Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start Service Area  */}
        <div className="service-area creative-service-wrapper ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--30 mb_sm--0">
                  <h2 className="title">Servicios</h2>
                  <p>
                    Ofrecemos todo nuestro conocimiento en cada sector para que juntos tomemos las mejores decisiones con datos contrastados.
                  </p>
                </div>
              </div>
            </div>
            <div className="row creative-service">
              <div className="col-lg-12">
                <ServiceList
                  item="6"
                  column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src={about}
                    alt="About Images"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">Proceso de implantación</h2>
                    <p className="description">
                      Nuestro proceso está dividido en cinco etapas que garantizan un enfoque estructurado y eficiente
                      para la implementación de proyectos empresariales. Cada paso representa un paso esencial en
                      el desarrollo y consolidación de iniciativas.
                    </p>
                  </div>
                  <div className="accordion-wrapper mt--30">
                    <Accordion01 />
                  </div>
                  <div className="about-button mt--50">
                    <a className="rn-button-style--2 btn-solid" href="/about">
                      Más información
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start Portfolio Area */}
        <div className="portfolio-area ptb--120 bg_color--1">
          <div className="portfolio-sacousel-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                    <h2 className="title">Proyectos en desarrollo</h2>
                    <p>
                      Diseñados para transformar el mercado y mejorar la experiencia del usuario,
                      reflejando nuestro compromiso con la excelencia y la tecnología de vanguardia.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <PortfolioList
                  styevariation="text-left mt--40"
                  column="col-lg-4 col-md-6 col-sm-6 col-12"
                  item="6"
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Area */}

        {/* Start Brand Area */}
        <div className="rn-brand-area bg_color--5 ptb--60">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">Solidarios</h2>
                    <p className="description">
                      Uno de nuestros principales principios es ser eficientes
                      y no se puede ser eficiente sin ser solidario.
                    </p>
                    <p className="description">
                      Para ello destinaremos en <b>8%</b> de los beneficios de cada proyecto
                      a la fundación contra el alzhéimer americana puesto que es en
                      este país en el que se realiza la actividad económica y es justo
                      devolverle una parte de las ganancias a los senior más necesitados de ayuda.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <div className="thumbnail position-relative">
                  <img
                    className="w-100"
                    src={solidarios}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        {/* Start Whatsapp Button */}
        <div>
          <WhatsAppButton> </WhatsAppButton>
        </div>
        {/* End Whatsapp Button */}

        {/* Start call To Action  */}
          <CallAction />
        {/* End call To Action  */}

        {/* Start Footer Style  */}
          <FooterTwo />
        {/* End Footer Style  */}
      </Fragment>
    );
  }
}
export default Business;
