import React, { useState } from 'react'
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { Link } from "react-router-dom";
import WhatsAppButton from "../component/WhatsAppButton";
import { FiChevronUp , FiCheck } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import serviceImg from "../assets/images/bg/plan-text-1.jpg";

const Plan01 = () => {

    const [formData, setFormData] = useState({
        fullname: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });

    // Nuevo estado para controlar el mensaje de éxito
    const [result, setResult] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Previene la redirección de la página

        // Crear un objeto FormData para enviar los datos
        const data = new FormData();
        Object.keys(formData).forEach(key => data.append(key, formData[key]));

        fetch('send_email.php', {
            method: 'POST',
            body: data
        })
        .then(response => response.text())
        .then(result => {
            setResult("Mensaje enviado con éxito"); // Actualiza el mensaje de éxito
            setFormData({ fullname: '', email: '', phone: '', subject: '', message: '' }); // Limpia el formulario
        })
        .catch(error => {
            console.error("Error al enviar el formulario:", error);
            setResult("Hubo un problema al enviar el mensaje."); // Mensaje de error
        });
    };

    return (
        <>
            <PageHelmet pageTitle='Plan Senior' />

            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}
            
            {/* Start Breadcrump Area */}
            <div
            className="rn-page-title-area pt--120 pb--190 bg_image plan-1"
            data-black-overlay="5"
            >
            <div className="container">
                <div className="row">
                <div className="col-lg-12">
                    <div className="rn-page-title text-center pt--100">
                    <h2 className="title theme-gradient">Plan Senior</h2>
                    <p>Plan económico para comenzar, inversión inicial baja y cuota mensual accesible</p>
                    </div>
                </div>
                </div>
            </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Plan Details */}
            <div className="rn-service-details pt--80 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">
                                    <div className="row sercice-details-content pb--80 align-items-center">
                                        <div className="col-lg-6 col-12">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <h4 className="title">Beneficios de este plan</h4>
                                                    <ul className="liststyle">
                                                        <li>Constitución empresa</li>
                                                        <li>Taxes ARS</li>
                                                        <li>Domicilio fiscal</li>
                                                        <li>Cuenta corriente / Banco Online</li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="thumb">
                                                <img
                                                    className="image_3"
                                                    src={serviceImg}
                                                    alt="Service Images"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='rn-portfolio-details pb--80'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <div className="portfolio-view-list d-flex">
                                            <div className="port-view">
                                                <span className="titulo-1">Costo inicial</span>
                                                <h4 className="precio-1">$3.000 USD</h4>
                                            </div>

                                            <div className="port-view">
                                                <span className="titulo-1">Costo mensual</span>
                                                <h4 className="precio-1">$800 USD</h4>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Plan Details */}

            {/* Start BLog Comment Form  */}
            <div className="blog-comment-form ptb--120 bg_color--5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner">
                                <h3 className="title mb--40 fontWeight500">¿Te interesa? Déjanos un mensaje</h3>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12 col-12">
                                            <div className="rnform-group">
                                                <input type="text" name="fullname" placeholder="Nombre" required value={formData.fullname} onChange={handleChange} />
                                            </div>
                                            <div className="rnform-group">
                                                <input type="email" name="email" placeholder="Email" required value={formData.email} onChange={handleChange} />
                                            </div>
                                            <div className="rnform-group">
                                                <input type="text" name="phone" placeholder="Teléfono" required value={formData.phone} onChange={handleChange} />
                                            </div>
                                            <div className="rnform-group">
                                                <input type="text" name="subject" placeholder="Asunto" required value={formData.subject} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-12">
                                            <div className="rnform-group">
                                                <textarea name="message" placeholder="Mensaje" required value={formData.message} onChange={handleChange}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="blog-btn">
                                                <button className="rn-button-style--2 btn-solid" type="submit">Enviar</button>
                                            </div>
                                        </div>
                                        {result && <p className="success-message" style={{ color: 'green', marginTop: '15px', marginLeft: '15px' }}>{result}</p>}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End BLog Comment Form  */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                {/* Start Pricing Table Area  */}
                <div className="rn-pricing-table-area ptb--120 bg_color--1">

                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="portfolio-details">
                                <div className="inner">
                                    <h2>Encuentra el plan para tu proyecto</h2>
                                    <p className="subtitle"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                    <div className="container">
                        <div className="row--1">

                            {/* Start PRicing Table Area  */}
                                <div className="rn-pricing active opacity_box_1">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Senior</h4>
                                        </div>
                                        <div className="pricing-header">
                                            <div className="pricing">
                                                <span className="price"><b>$3.000 USD</b></span>
                                                <span className="subtitle">Inicial</span>
                                                <span className="price"><b>$800 USD</b></span>
                                                <span className="subtitle">Mensual</span>
                                            </div>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn">
                                                Estás aquí
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                                <div className="rn-pricing opacity_box_2">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Ejecutive</h4>
                                        </div>
                                        <div className="pricing-header">
                                            <div className="pricing">
                                                <span className="price"><b>$3.000 USD</b></span>
                                                <span className="subtitle">Inicial</span>
                                                <span className="price"><b>$1.800 USD</b></span>
                                                <span className="subtitle">Mensual</span>
                                            </div>
                                        </div>
                                        <div className="pricing-footer">
                                            <Link className="rn-btn" to="/plan-ejecutive">
                                                Más información
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                                <div className="rn-pricing opacity_box_3">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Advanced</h4>
                                        </div>
                                        <div className="pricing-header">
                                            <div className="pricing">
                                                <span className="price"><b>$3.000 USD</b></span>
                                                <span className="subtitle">Inicial</span>
                                                <span className="price"><b>$5.000 USD</b></span>
                                                <span className="subtitle">Mensual</span>
                                            </div>
                                        </div>
                                        <div className="pricing-footer">
                                            <Link className="rn-btn" to="/plan-advanced">
                                                Más información
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                                <div className="rn-pricing opacity_box_4">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Expert</h4>
                                        </div>
                                        <div className="pricing-header">
                                            <div className="pricing">
                                                <span className="price"><b>$5.000 USD</b></span>
                                                <span className="subtitle">Inicial</span>
                                                <span className="price"><b>$8.000 USD</b></span>
                                                <span className="subtitle">Mensual</span>
                                            </div>
                                        </div>
                                        <div className="pricing-footer">
                                            <Link className="rn-btn" to="/plan-expert">
                                                Más información
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                                <div className="rn-pricing opacity_box_5">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Management</h4>
                                        </div>
                                        <div className="pricing-header">
                                            <div className="pricing">
                                                <span className="price"><b>$8.000 USD</b></span>
                                                <span className="subtitle">Inicial</span>
                                                <span className="subtitle"><b>Costo mensual de acuerdo a tus necesidades</b></span>
                                            </div>
                                        </div>
                                        <div className="pricing-footer">
                                            <Link className="rn-btn" to="/plan-management">
                                                Más información
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}
                        </div>
                    </div>
                {/* End Pricing Table Area  */}
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* Start Whatsapp Button */}
                <div>
                <WhatsAppButton> </WhatsAppButton>
                </div>
            {/* End Whatsapp Button */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}
        </>
        
    )
}
export default Plan01;