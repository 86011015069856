import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import ContactTwo from "../elements/contact/ContactTwo";
import ScrollToTop from 'react-scroll-up';
import WhatsAppButton from "../component/WhatsAppButton";
import GoogleMap from "../component/GoogleMap";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contact extends Component{
    // static defaultProps = {
    //     center: {
    //         lat: 25.77,
    //         lng: -80.18
    //     },
    //     zoom: 11
    // };

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Contact' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Breadcrump Area */}
            <div
            className="rn-page-title-area pt--120 pb--190 bg_image services-12"
            data-black-overlay="6"
            >
            <div className="container">
                <div className="row">
                <div className="col-lg-12">
                    <div className="rn-page-title text-center pt--100">
                    <h2 className="title theme-gradient">Contacto</h2>
                    <p>
                        Queremos conocer tus proyectos y explorar oportunidades de colaboración en USA
                        <br />
                        ¡Hagamos realidad tus ideas!
                    </p>
                    </div>
                </div>
                </div>
            </div>
            </div>
            {/* End Breadcrump Area */}

                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--120 bg_color--1">
                    <div className="container">                      
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Teléfono</h4>
                                        <p><a href="tel:+17866047755">+1 (786) 604 7755</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Email</h4>
                                        <p><a href="mailto:info@abybacorporate.com">info@abybacorporate.com</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Ubicación</h4>
                                        <p>1395 Brickell Ave | Miami, Florida</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--120 bg_color--5">
                    <ContactTwo />
                </div>
                {/* End Contact Page Area  */}

                {/* Start Contact Map  */}
                <div className="rn-contact-map-wrapper ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            {/* Start Single Map  */}
                            <div className="col-lg-12 col-12">
                                <div className="rn-contact-map-area position-relative">
                                    <div>
                                        <GoogleMap />
                                    </div>
                                </div>
                            </div>
                            {/* End Single Map  */}
                        </div>
                    </div>
                </div>
                {/* End Contact Map  */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                {/* Start Whatsapp Button */}
                <div>
                    <WhatsAppButton> </WhatsAppButton>
                </div>
                {/* End Whatsapp Button */}

                <Footer />
                
            </React.Fragment>
        )
    }
}
export default Contact