import React, { Component } from "react";

import brand1 from "../assets/images/brand/consul_miami.png";
import brand2 from "../assets/images/brand/consul_colombia.png";
import brand3 from "../assets/images/brand/icex.png";
import brand4 from "../assets/images/brand/madrid.png";
import brand5 from "../assets/images/brand/camara_madrid.png";
import brand6 from "../assets/images/brand/comunidad.png";

class BrandTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="col-lg-12">
          <div className="section-title service-style--3 text-center mb_sm--0">
            <h2 className="title2">Colaboradores institucionales</h2>
          </div>
        </div>
        <ul className="brand-style-2">
          <li>
            <img src={brand1} alt="Logo Images" />
          </li>
          <li>
            <img src={brand2} alt="Logo Images" />
          </li>
          <li>
            <img src={brand3} alt="Logo Images" />
          </li>
          <li>
            <img src={brand4} alt="Logo Images" />
          </li>
          <li>
            <img src={brand5} alt="Logo Images" />
          </li>
          <li>
            <img src={brand6} alt="Logo Images" />
          </li>
        </ul>
      </React.Fragment>
    );
  }
}
export default BrandTwo;
