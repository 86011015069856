import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import WhatsAppButton from "../component/WhatsAppButton";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import serviceImg from "../assets/images/service/service-08.png";

class ServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Diseño, IA y social media" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image services-5"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Diseño, IA y social media</h2>
                  <p>Desarrollo o rediseño web, diseño visual, imagen corporativa e implementación de IA en diferentes procesos</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="image_1"
                            src={serviceImg}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>
                            Buscamos crear experiencias personalizadas y adaptadas
                            a cada necesidad, logrando que cada detalle cuente.
                            Nos encargamos de cada etapa, asegurando siempre resultados
                            impecables y efectivos.
                          </p>
                          <h4 className="title">Especialidades y fortalezas de nuestro Equipo</h4>
                          <ul className="liststyle">
                            <li>Creación de identidades visuales impactantes y funcionales</li>
                            <li>Desarrollo o rediseño de sitios web personalizados y optimizados</li>
                            <li>Integración de inteligencia artificial para la automatización y mejora de procesos</li>
                            <li>Diseño UI/UX enfocado en la experiencia del usuario</li>
                            <li>Adaptación y uso de tecnologías emergentes para soluciones innovadoras</li>
                            <li>Implementación de sistemas responsivos y accesibles en todas las plataforma</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        {/* Start Whatsapp Button */}
          <div>
            <WhatsAppButton> </WhatsAppButton>
          </div>
        {/* End Whatsapp Button */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default ServiceDetails;
