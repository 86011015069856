import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import WhatsAppButton from "../component/WhatsAppButton";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import serviceImg from "../assets/images/service/service-07.png";

class ServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Real estate" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image services-4"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Real estate</h2>
                  <p>Gestión de propiedades, análisis de mercado y estrategias para maximizar el valor de activos inmobiliarios</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="image_1"
                            src={serviceImg}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">Corporativo</h4>
                          <p>
                            Seleccionamos los datos inmobiliarios de cada sector
                            esto nos permite identificar las mejores zonas de influencia
                            para cada negocio teniendo en cuenta los próximos desarrollos,
                            la tendencia del mercado y de cada zona. <br />
                            Identificamos las áreas de influencia para cada negocio
                            teniendo en cuenta sus  necesidades.
                          </p>
                          <h4 className="title">Residencial</h4>
                          <p>
                            Analizamos e identificamos las mejores zonas para ubicar cada
                            tipo de vivienda según las necesidades de cada cliente bien sea
                            para vivir o como inversión atendido en todo momento con un
                            bróker personalizado. <br />
                            Adicionalmente ofrecemos los servicios de proyectos arquitectónicos
                            ingeniería adecuaciones  y gestión de licencias.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        {/* Start Whatsapp Button */}
          <div>
            <WhatsAppButton> </WhatsAppButton>
          </div>
        {/* End Whatsapp Button */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default ServiceDetails;
