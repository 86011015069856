import React from 'react'
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import WhatsAppButton from "../component/WhatsAppButton";
import { FiChevronUp , FiCheck } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { Link } from "react-router-dom";

const PricingTable = () => {
    return (
        <>
            <PageHelmet pageTitle='Soluciones' />

            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}
            
            {/* Start Breadcrump Area */}
            <div
            className="rn-page-title-area pt--120 pb--190 bg_image plan-0"
            data-black-overlay="5"
            >
            <div className="container">
                <div className="row">
                <div className="col-lg-12">
                    <div className="rn-page-title text-center pt--100">
                    <h2 className="title theme-gradient">Soluciones</h2>
                    <p>Descubre nuestros planes personalizados para impulsar tus proyectos</p>
                    </div>
                </div>
                </div>
            </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                {/* Start Pricing Table Area  */}
                <div className="rn-pricing-table-area ptb--100 bg_color--1">
                    <div className="container">
                        <div className="row--1">

                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="portfolio-details">
                                        <div className="inner">
                                            <h2>Encuentra el plan para tu proyecto</h2>
                                            <p className="subtitle"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                            {/* Start PRicing Table Area  */}
                                <div className="rn-pricing opacity_box_1">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Senior</h4>
                                        </div>
                                        {/* <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Constitución empresa</li>
                                                <li><FiCheck /> Taxes ARS</li>
                                                <li><FiCheck /> Domicilio fiscal</li>
                                                <li><FiCheck /> Cuenta corriente <br />Banco Online</li>
                                            </ul>
                                        </div> */}
                                        <div className="pricing-header">
                                            <div className="pricing">
                                                <span className="price"><b>$3.000 USD</b></span>
                                                <span className="subtitle">Inicial</span>
                                                <span className="price"><b>$800 USD</b></span>
                                                <span className="subtitle">Mensual</span>
                                            </div>
                                        </div>
                                        <div className="pricing-footer">
                                            <Link className="rn-btn" to="/plan-senior">
                                                Más información
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                                <div className="rn-pricing opacity_box_2">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Ejecutive</h4>
                                        </div>
                                        {/* <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Constitución empresa</li>
                                                <li><FiCheck /> Taxes ARS</li>
                                                <li><FiCheck /> Domicilio fiscal</li>
                                                <li><FiCheck /> Cuenta corriente <br />Online Cash Back</li>
                                                <li><FiCheck /> Recepsión de correspondencia / Paquetes</li>
                                                <li><FiCheck /> Número de teléfono local con respuesta corporativa</li>
                                            </ul>
                                        </div> */}
                                        <div className="pricing-header">
                                            <div className="pricing">
                                                <span className="price"><b>$3.000 USD</b></span>
                                                <span className="subtitle">Inicial</span>
                                                <span className="price"><b>$1.800 USD</b></span>
                                                <span className="subtitle">Mensual</span>
                                            </div>
                                        </div>
                                        <div className="pricing-footer">
                                            <Link className="rn-btn" to="/plan-ejecutive">
                                                Más información
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                                <div className="rn-pricing opacity_box_3">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Advanced</h4>
                                        </div>
                                        {/* <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Constitución empresa</li>
                                                <li><FiCheck /> Taxes ARS</li>
                                                <li><FiCheck /> Domicilio fiscal</li>
                                                <li><FiCheck /> Cuenta corriente <br />Online Cash Back</li>
                                                <li><FiCheck /> Recepsión de correspondencia / Paquetes</li>
                                                <li><FiCheck /> Número de teléfono local con respuesta corporativa</li>
                                                <li><FiCheck /> Desvio de llamadas</li>
                                                <li><FiCheck /> Ejecutivo de ventas local</li>
                                                <li><FiCheck /> Vehículo corporativo rotulado con logo de la compañía</li>
                                                <li><FiCheck /> Control de objetivos de ventas</li>
                                                <li><FiCheck /> Supervisión de objetivos</li>
                                            </ul>
                                        </div> */}
                                        <div className="pricing-header">
                                            <div className="pricing">
                                                <span className="price"><b>$3.000 USD</b></span>
                                                <span className="subtitle">Inicial</span>
                                                <span className="price"><b>$5.000 USD</b></span>
                                                <span className="subtitle">Mensual</span>
                                            </div>
                                        </div>
                                        <div className="pricing-footer">
                                            <Link className="rn-btn" to="/plan-advanced">
                                                Más información
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                                <div className="rn-pricing opacity_box_4">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Expert</h4>
                                        </div>
                                        {/* <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /><b>Plan Advance +</b></li>
                                            </ul>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Gestión logistica</li>
                                                <li><FiCheck /> Show Room</li>
                                                <li><FiCheck /> Control de stock</li>
                                                <li><FiCheck /> Pedidos</li>
                                                <li><FiCheck /> Importación y gestión de mercancias</li>
                                                <li><FiCheck /> Implantación y gestión de redes sociales locales</li>
                                                <li><FiCheck /> Tienda Online</li>
                                            </ul>
                                        </div> */}
                                        <div className="pricing-header">
                                            <div className="pricing">
                                                <span className="price"><b>$5.000 USD</b></span>
                                                <span className="subtitle">Inicial</span>
                                                <span className="price"><b>$8.000 USD</b></span>
                                                <span className="subtitle">Mensual</span>
                                            </div>
                                        </div>
                                        <div className="pricing-footer">
                                            <Link className="rn-btn" to="/plan-expert">
                                                Más información
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                                <div className="rn-pricing opacity_box_5">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Management</h4>
                                        </div>
                                        {/* <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Constitución empresa</li>
                                                <li><FiCheck /> Gestión logistica</li>
                                                <li><FiCheck /> Sede corporativa</li>
                                                <li><FiCheck /> Consultor experto en implantación de negocios en EEUU</li>
                                                <li><FiCheck /> Estrategia corporativa</li>
                                                <li><FiCheck /> Reinversion de capital con alta rentabilidad</li>
                                                <li><FiCheck /> Estrategias financieras y fiscales</li>
                                                <li><FiCheck /> Inversión y gestión de patrimonio inmobiliario</li>
                                                <li><FiCheck /> Asesoramiento juridico</li>
                                            </ul>
                                        </div> */}
                                        <div className="pricing-header">
                                            <div className="pricing">
                                                <span className="price"><b>$8.000 USD</b></span>
                                                <span className="subtitle">Inicial</span>
                                                <span className="subtitle"><b>Costo mensual de acuerdo a tus necesidades</b></span>
                                            </div>
                                        </div>
                                        <div className="pricing-footer">
                                            <Link className="rn-btn" to="/plan-management">
                                                Más información
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}
                        </div>
                    </div>
                {/* End Pricing Table Area  */}
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* Start Whatsapp Button */}
                <div>
                <WhatsAppButton> </WhatsAppButton>
                </div>
            {/* End Whatsapp Button */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}
        </>
        
    )
}
export default PricingTable;